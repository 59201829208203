import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SageEnum } from '../core';
import { SharedUtilUiModule } from '@theia-cc/shared/util-ui';
@Component({
  selector: 'theia-cc-header',
  standalone: true,
  template: `<div class="main-bg py-3">
    <header class="container d-flex align-items-center flex-wrap gap-1">
      <a [href]="logoLinkUrl" class="header-logo">
        <img [src]="logoImageUrl" />
      </a>
      <div class="address-card" *ngIf="clientAddress">
        <img src="assets/House.png" alt="address card" />
        <p class="p-m" [innerText]="clientAddress"></p>
      </div>
    </header>
    <section
      class="container d-flex justify-content-between align-items-center gap-2 header-container">
      <div class="header-column">
        <img class="w-100" [src]="headerImageUrl" alt="building picture" />
      </div>
      <div class="flex-column gap-2 header-column" *ngIf="stage !== SageEnum.ABORT; else abort">
        <h5>{{ translations['phase.title'] }}</h5>
        <h1 [innerText]="translations['phase.label']"></h1>
        <p [innerText]="translations['phase.text']"></p>
      </div>
    </section>

    <ng-template #abort>
      <h3 class="header-column" [innerText]="translations['error.abortMessage']"></h3>
    </ng-template>
  </div>`,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, SharedUtilUiModule],
})
export class HeaderComponent {
  @Input() clientAddress: string = '';
  @Input() translations: Record<string, string>;
  @Input() stage: SageEnum;
  @Input() logoLinkUrl: string;
  @Input() logoImageUrl: string;
  @Input() headerImageUrl: string;
  protected readonly SageEnum = SageEnum;
}
