// https://sreyaj.dev/customizable-accordion-component-angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AccordionComponent } from './accordion.component';
import { AccordionItem } from './accordion-item.directive';
import { AccordionContent } from './accordion-content.directive';
import { AccordionTitle } from './accordion-title.directive';
import { AccordionHeader } from './accordion-header.directive';
import { TogglePipe } from './toggle.pipe';
import { AccordionNavigationDirective } from './accordion-navigation.directive';

@NgModule({
  declarations: [
    AccordionComponent,
    AccordionItem,
    AccordionContent,
    AccordionNavigationDirective,
    AccordionTitle,
    AccordionHeader,
    TogglePipe,
  ],
  imports: [CommonModule],
  exports: [
    CommonModule,
    AccordionComponent,
    AccordionItem,
    AccordionContent,
    AccordionNavigationDirective,
    AccordionTitle,
    AccordionHeader,
  ],
})
export class AccordionModule {}
