import { Color, ILanguageSpecificStringWithType } from '@theia-cc/shared/models';

export enum ProgressStatusEnum {
  done,
  progress,
  none,
}

export type ContactPersonData = {
  fullName: string;
  center: string;
  phone: string;
  email: string;
  picture?: string;
  jobTitle?: string;
};

export type ProgressStep = {
  title: string;
  status: ProgressStatusEnum;
};

export type ProgressPhase = ProgressStep & {
  phaseName: PhasesEnum;
  steps: ProgressStep[];
  date?: Date;
  endDate?: Date;
  dateLabel?: string;
};

export enum SageEnum {
  FOLLOWUP_BO = 'FOLLOWUP_BO',
  ABORT = 'ABORT',
  ON_HOLD = 'ON_HOLD',
  WON = 'WON',
  PROJECT_ASSIGNED = 'PROJECT_ASSIGNED',
  PROJECT_IN_PROGRESS = 'PROJECT_IN_PROGRESS',
  PROJECT_FINISHED = 'PROJECT_FINISHED',
  PROJECT_ARCHIVED = 'PROJECT_ARCHIVED',
  PROJECT_ACCOUNTED = 'PROJECT_ACCOUNTED',
  PROJECT_IN_OPERATION = 'PROJECT_IN_OPERATION',
}

export type Data = {
  clientAddress: string;
  customerRepInfo: ContactPersonData;
  projectContactInfo: ContactPersonData;
  checklist: ProgressPhase[];
  translations: Record<string, string>;
  stage: SageEnum;
  logoLinkUrl: string;
  logoImageUrl: string;
  heroHeaderImageUrl?: string;
  leadId: string;
  notificationEnabled: boolean;
  activePhaseIndex: number;
};

export type Language = 'de' | 'fr' | 'it';

export enum PhasesEnum {
  Definition = 'Definition',
  Planning = 'Planning',
  Sourcing = 'Sourcing',
  Preparation = 'Preparation',
  Installation = 'Installation',
  Closure = 'Closure',
}

export interface ITtTranslations {
  checklist: {
    ClosureLabel: ILanguageSpecificStringWithType;
    ClosureText: ILanguageSpecificStringWithType;
    DefinitionLabel: ILanguageSpecificStringWithType;
    DefinitionText: ILanguageSpecificStringWithType;
    InstallationLabel: ILanguageSpecificStringWithType;
    InstallationText: ILanguageSpecificStringWithType;
    PlanningLabel: ILanguageSpecificStringWithType;
    PlanningText: ILanguageSpecificStringWithType;
    PreparationLabel: ILanguageSpecificStringWithType;
    PreparationText: ILanguageSpecificStringWithType;
    SourcingLabel: ILanguageSpecificStringWithType;
    SourcingText: ILanguageSpecificStringWithType;
    HomecheckDateLabel: ILanguageSpecificStringWithType;
    MontageDateLabel: ILanguageSpecificStringWithType;
    CommissioningDateLabel: ILanguageSpecificStringWithType;
    MontageDateMonthRange: ILanguageSpecificStringWithType;
    MontageDateMonthRangeFooter: ILanguageSpecificStringWithType;
    MontageDateShortBefore: ILanguageSpecificStringWithType;
    MontageDateShortBeforeFooter: ILanguageSpecificStringWithType;
  };
  contact: {
    title: ILanguageSpecificStringWithType;
    info: ILanguageSpecificStringWithType;
    mailSectionSignupTitle: ILanguageSpecificStringWithType;
    mailSectionSignupText: ILanguageSpecificStringWithType;
    mailSectionSignupButton: ILanguageSpecificStringWithType;
    mailSectionCancellation: ILanguageSpecificStringWithType;
    mailSectionSignupSuccess: ILanguageSpecificStringWithType;
    mailSectionCancellationSuccess: ILanguageSpecificStringWithType;
  };
  error: {
    server: ILanguageSpecificStringWithType;
    noLeadId: ILanguageSpecificStringWithType;
    abortMessage: ILanguageSpecificStringWithType;
  };
}
export interface IConfigTT {
  contactEmail: string;
  contactPhone: string;
  customComponentStyles: Record<string, Color>;
  faviconUrl: string;
  fullName: string;
  logoImageUrl: string;
  heroHeaderImageUrl?: string;
  organizationalUnit: string;
  logoLinkUrl: string;
  theme: { name: string; hex: string }[];
}
