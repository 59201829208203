import { ContentChild, Directive, Input } from '@angular/core';
import { AccordionContent } from './accordion-content.directive';
import { AccordionHeader } from './accordion-header.directive';
import { AccordionTitle } from './accordion-title.directive';
import { AccordionNavigationDirective } from './accordion-navigation.directive';

@Directive({
  selector: 'theia-cc-accordion-item',
})
export class AccordionItem {
  @Input() title = '';
  @Input() disabled = false;
  @Input() expanded = false;
  @ContentChild(AccordionNavigationDirective) navigation: AccordionNavigationDirective;
  @ContentChild(AccordionContent) content: AccordionContent;
  @ContentChild(AccordionTitle) customTitle: AccordionTitle;
  @ContentChild(AccordionHeader) customHeader: AccordionHeader;
}
