import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressStatusEnum } from '../core';

@Component({
  selector: 'theia-cc-status-icon',
  standalone: true,
  template: `<ng-container [ngSwitch]="status">
    <svg [style.width.px]="size" [style.height.px]="size" *ngSwitchDefault>
      <use [attr.xlink:href]="'assets/icons.svg#' + statusIcons[status]"></use>
    </svg>
  </ng-container>`,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule],
})
export class StatusIconComponent {
  @Input() status: ProgressStatusEnum = ProgressStatusEnum.none;
  @Input() size: number = 24;
  statusIcons = {
    [ProgressStatusEnum.done]: 'check',
    [ProgressStatusEnum.progress]: 'reset',
    [ProgressStatusEnum.none]: '',
  };
}
