import { Component, Inject, LOCALE_ID, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule, DOCUMENT, registerLocaleData } from '@angular/common';
import { HeaderComponent } from './blocks/header.component';
import { ContactComponent } from './blocks/contact.component';
import { StepsComponent } from './blocks/steps.component';
import { Data, DataService, Language, SageEnum } from './core';
import { ENVIRONMENT } from '@theia-cc/shared/helpers';
import {
  SanityImageService,
  SanityService,
  TransformSanityDataService,
} from '@theia-cc/shared/services';
import { environment } from '../environments/environment';
import localeIt from '@angular/common/locales/it-CH';
import localeDe from '@angular/common/locales/de-CH';
import localeFr from '@angular/common/locales/fr-CH';
import { Title } from '@angular/platform-browser';
import { AlertModule } from 'ngx-bootstrap/alert';

@Component({
  standalone: true,
  selector: 'theia-cc-root',
  template: ` <alert
      type="success"
      [dismissible]="true"
      [dismissOnTimeout]="5000"
      *ngIf="successMessage">
      {{ successMessage }}
    </alert>
    <ng-container *ngIf="data; else spinner">
      <theia-cc-header
        [stage]="data?.stage"
        [clientAddress]="data?.clientAddress"
        [translations]="data?.translations"
        [logoImageUrl]="data?.logoImageUrl"
        [logoLinkUrl]="data?.logoLinkUrl"
        [headerImageUrl]="data?.heroHeaderImageUrl ?? 'assets/House.png'"></theia-cc-header>
      <theia-cc-steps
        *ngIf="data?.stage !== SageEnum.ABORT && data?.checklist?.length"
        [translations]="data?.translations"
        [activePhaseIndex]="data?.activePhaseIndex"
        [checklist]="data?.checklist"></theia-cc-steps>
      <section class="block-padding main-bg">
        <div class="container contact-container">
          <h2 class="h1 mb-3" [innerText]="data?.translations['contact.title']"></h2>
          <div class="py-2 mb-5" *ngIf="data?.projectContactInfo">
            <theia-cc-contact [contactPersonData]="data?.projectContactInfo"></theia-cc-contact>
          </div>
          <div class="py-2 mb-5">
            <theia-cc-contact [contactPersonData]="data?.customerRepInfo"></theia-cc-contact>
          </div>
          <h5 [innerText]="data?.translations['contact.info']"></h5>
        </div>
      </section>
      <section class="mail-section" [class.mail-section__enabled]="notificationEnabled">
        <div
          class="container d-flex gap-3 align-items-start justify-content-center flex-column flex-md-row">
          <div class="mail-section__icon">
            <svg>
              <use [attr.xlink:href]="'assets/icons.svg#email'"></use>
            </svg>
          </div>
          <div class="mail-section__content">
            <ng-container *ngIf="!notificationEnabled">
              <h3>{{ data?.translations['contact.mailSectionSignupTitle'] }}</h3>
              <p class="mt-05">{{ data?.translations['contact.mailSectionSignupText'] }}</p>
              <button class="mt-3 cursor-pointer" (click)="subscribeToEmailNotifications()">
                {{ data?.translations['contact.mailSectionSignupButton'] }}
              </button>
            </ng-container>

            <p
              *ngIf="notificationEnabled"
              class="cursor-pointer"
              (click)="unsubscribeToEmailNotifications()"
              [innerHtml]="data?.translations['contact.mailSectionCancellation']"></p>
          </div>
        </div>
      </section>

      <div class="spinner-wrapper spinner-wrapper__opacity" *ngIf="loading">
        <div class="spinner"></div>
      </div>
    </ng-container>
    <ng-template #spinner>
      <div class="spinner-wrapper">
        <div class="spinner"></div>
      </div>
    </ng-template>`,
  imports: [AlertModule, CommonModule, ContactComponent, HeaderComponent, StepsComponent],
  providers: [
    { provide: ENVIRONMENT, useValue: environment },
    {
      provide: LOCALE_ID,
      useFactory: () => `${getLangFromParams(new URLSearchParams(document.location.search))}-CH`,
    },
    SanityService,
    DataService,
    TransformSanityDataService,
    SanityImageService,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  protected readonly SageEnum = SageEnum;
  data: Data;
  notificationEnabled: boolean;
  loading: boolean;
  successMessage: string;

  constructor(
    public dataService: DataService,
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title
  ) {}

  public async ngOnInit() {
    registerLocaleData(localeDe, 'de-CH');
    registerLocaleData(localeFr, 'fr-CH');
    registerLocaleData(localeIt, 'it-CH');
    const searchParams = new URLSearchParams(this.document.location.search);
    const language = getLangFromParams(searchParams);
    const leadId = searchParams.get('leadId') || searchParams.get('lead');
    this.data = await this.dataService.getData(leadId, language as Language);
    if (leadId) {
      this.titleService.setTitle(`${this.data?.translations?.['common.pageTitle']} - ${leadId}`);
    }
    this.notificationEnabled = this.data?.notificationEnabled;
  }

  public subscribeToEmailNotifications() {
    this.successMessage = '';
    this.loading = true;
    this.dataService
      .subscribeToEmailNotifications(this.data.leadId)
      .then(() => {
        this.notificationEnabled = true;
      })
      .finally(() => {
        this.loading = false;
        this.successMessage = this.data.translations?.['contact.mailSectionSignupSuccess'];
      });
  }

  public unsubscribeToEmailNotifications() {
    this.successMessage = '';
    this.loading = true;
    this.dataService
      .unsubscribeToEmailNotifications(this.data.leadId)
      .then(() => {
        this.notificationEnabled = false;
      })
      .finally(() => {
        this.loading = false;
        this.successMessage = this.data.translations?.['contact.mailSectionCancellationSuccess'];
      });
  }
}

export function getLangFromParams(searchParams: URLSearchParams) {
  return (
    searchParams.get('Language') || searchParams.get('language') || searchParams.get('lang') || 'de'
  );
}
