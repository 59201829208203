import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhasesEnum, ProgressPhase } from '../core';

@Component({
  selector: 'steps-footer-note',
  standalone: true,
  template: `<p
      class="p-m mt-1"
      *ngIf="
        (installationDate && activePhaseIndex < 3) || (finalDate && !isFinalDateIn3DaysOrLess)
      ">
      {{ translations['checklist.MontageDateMonthRangeFooter'] }}
    </p>
    <p
      class="p-m mt-1"
      *ngIf="installationDate && activePhaseIndex === 3 && !isInstallationDateIn3DaysOrLess">
      {{ translations['checklist.MontageDateShortBeforeFooter'] }}
    </p>`,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule],
})
export class StepsFooterNoteComponent implements OnChanges {
  @Input() translations: Record<string, string>;
  @Input() activePhaseIndex: number;
  @Input() checklist: ProgressPhase[];

  public isInstallationDateIn3DaysOrLess = false;
  public isFinalDateIn3DaysOrLess = false;
  public installationDate: number;
  public finalDate: number;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.checklist) {
      this.installationDate = this.checklist
        .find(phase => phase.phaseName === PhasesEnum.Installation)
        ?.date?.setHours(0);
      this.isInstallationDateIn3DaysOrLess =
        this.installationDate &&
        this.installationDate < new Date().setHours(0) + 1000 * 60 * 60 * 24 * 3;

      this.finalDate = this.checklist
        .find(phase => phase.phaseName === PhasesEnum.Closure)
        ?.date?.setHours(0);
      this.isFinalDateIn3DaysOrLess =
        this.finalDate && this.finalDate < new Date().setHours(0) + 1000 * 60 * 60 * 24 * 3;
    }
  }
}
