import { trigger, state, style, transition, animate } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccordionItem } from './accordion-item.directive';

@Component({
  selector: 'theia-cc-accordion',
  template: `<section class="accordion">
      <div
        *ngFor="let item of items; index as i"
        class="accordion__item"
        [class.disabled]="item.disabled"
        [class.active]="expanded.has(i)">
        <ng-container *ngIf="item?.navigation?.templateRef">
          <ng-container [ngTemplateOutlet]="item.navigation.templateRef"></ng-container>
        </ng-container>
        <div class="flex-grow-1">
          <ng-container
            [ngTemplateOutlet]="item?.customHeader?.templateRef || defaultHeader"
            [ngTemplateOutletContext]="{
              $implicit: item,
              index: i,
              toggle: i | getToggleFunction: toggleState
            }"></ng-container>
          <div
            class="accordion__content"
            [class.expanded]="expanded.has(i)"
            [@contentExpansion]="expanded.has(i) ? 'expanded' : 'collapsed'">
            <ng-container *ngTemplateOutlet="item.content.templateRef"></ng-container>
          </div>
        </div>
      </div>
    </section>

    <ng-template #defaultHeader let-item let-index="index">
      <header class="accordion__header" (click)="item.disabled ? {} : toggleState(index)">
        <ng-container
          *ngTemplateOutlet="item?.customTitle?.templateRef || defaultTitle"></ng-container>
        <button class="accordion__toggle-btn" [disabled]="item.disabled">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 12L16 19L23 12"
              stroke="currentColor"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
      </header>
      <ng-template #defaultTitle>
        <h3 class="accordion__title">{{ item?.title }}</h3>
      </ng-template>
    </ng-template> `,
  styles: [
    `
      :host {
        --accordion-disabled: hsl(0, 0%, 60%);
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('contentExpansion', [
      state('expanded', style({ height: '*', opacity: 1, visibility: 'visible' })),
      state('collapsed', style({ height: '0px', opacity: 0, visibility: 'hidden' })),
      transition('expanded <=> collapsed', animate('200ms cubic-bezier(.37,1.04,.68,.98)')),
    ]),
  ],
})
export class AccordionComponent implements AfterViewInit {
  expanded = new Set<number>();
  /**
   * Decides if the single item will be open at once or not.
   * In collapsing mode, toggling one would collapse others
   */
  @Input() collapsing = true;

  @ContentChildren(AccordionItem) items: QueryList<AccordionItem>;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    merge(this.items.changes, of(this.items))
      .pipe(map(() => this.items.toArray()))
      .subscribe(items => {
        items.forEach((item, index) => {
          if (item.expanded) {
            this.expanded.add(index);
          }
        });
        this.cdr.detectChanges();
      });
  }

  toggleState = (index: number) => {
    if (this.expanded.has(index)) {
      this.expanded.delete(index);
    } else {
      if (this.collapsing) {
        this.expanded.clear();
      }
      this.expanded.add(index);
    }
  };
}
