import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AccordionModule, StatusIconComponent } from '../components';
import { ProgressPhase, ProgressStatusEnum } from '../core';
import { PhaseDateComponent } from '../components/phase-date';
import { StepsFooterNoteComponent } from '../components/steps-footer-note';

@Component({
  selector: 'theia-cc-steps',
  standalone: true,
  template: `<main
      class="container block-padding steps-container"
      [class.steps-container]="!isDatesOnly">
      <theia-cc-accordion [collapsing]="false" *ngIf="!isDatesOnly; else dateOnly">
        <theia-cc-accordion-item
          *ngFor="let phase of checklist"
          [expanded]="phase.status === ProgressStatusEnum.progress">
          <ng-template accordionTitle>
            <div>
              <h3 class="phase-title" [class.completed]="!phase.status">{{ phase.title }}</h3>
              <phase-date
                [phase]="phase"
                [translations]="translations"
                [activePhaseIndex]="activePhaseIndex"
                [checklist]="checklist"></phase-date>
            </div>
          </ng-template>
          <ng-template accordionNavigation>
            <div class="flex-column align-items-center">
              <div class="status-round status-round__lg">
                <theia-cc-status-icon [status]="phase.status" [size]="22"></theia-cc-status-icon>
              </div>
              <hr class="accordion__navigation-line" />
            </div>
          </ng-template>

          <ng-template accordionContent>
            <div class="phase-content">
              <ng-container *ngFor="let step of phase.steps">
                <div class="d-flex align-items-center gap-1 pb-05">
                  <div class="status-round">
                    <theia-cc-status-icon [status]="step.status" [size]="12"></theia-cc-status-icon>
                  </div>
                  <p class="step-title p-m bold" [class.completed]="!step.status">
                    {{ step.title }}
                  </p>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </theia-cc-accordion-item>
      </theia-cc-accordion>

      <steps-footer-note
        [translations]="translations"
        [activePhaseIndex]="activePhaseIndex"
        [checklist]="checklist"></steps-footer-note>
    </main>
    <ng-template #dateOnly>
      <div class="phase-content">
        <ng-container *ngFor="let phase of checklist">
          <phase-date
            [phase]="phase"
            [translations]="translations"
            [activePhaseIndex]="activePhaseIndex"
            [checklist]="checklist"></phase-date>
        </ng-container>
      </div>
    </ng-template>`,
  imports: [AccordionModule, StatusIconComponent, PhaseDateComponent, StepsFooterNoteComponent],
  encapsulation: ViewEncapsulation.None,
})
export class StepsComponent implements OnChanges {
  @Input() translations: Record<string, string>;
  @Input() activePhaseIndex: number;
  @Input() checklist: ProgressPhase[];
  public isDatesOnly = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.checklist) {
      this.isDatesOnly = this.checklist.every(phase => !phase.title);
    }
  }

  protected readonly ProgressStatusEnum = ProgressStatusEnum;
}
