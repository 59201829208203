import { Inject, Injectable } from '@angular/core';
import { THEME_TOKENS } from './consts';
import {
  ContactPersonData,
  Data,
  IConfigTT,
  ITtTranslations,
  Language,
  PhasesEnum,
  ProgressPhase,
  ProgressStatusEnum,
} from './types';
import { SanityService } from '@theia-cc/shared/services';
import {
  appendCustomFaviconToHead,
  ENVIRONMENT,
  IEnvironment,
  injectDynamicThemeTokensIntoHtmlHead,
} from '@theia-cc/shared/helpers';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

const filterPrivateFields = (obj: any): Record<string, any> => {
  const res = {};
  for (const key in obj) {
    if (!key.startsWith('_')) {
      res[key] = obj[key];
    }
  }

  return res;
};

@Injectable()
export class DataService {
  constructor(
    private domSanitizer: DomSanitizer,
    @Inject(ENVIRONMENT) private environment: IEnvironment,
    private sanityService: SanityService,
    @Inject(DOCUMENT) private document: Document
  ) {}
  public async getData(leadId: string | null, language: Language): Promise<Data> {
    const searchParams = new URLSearchParams(this.document.location.search);
    const partnerId = searchParams.get('partnerId') || searchParams.get('partnerid') || 'default';
    const langCode = this.capitalizeFirstLetter(language);
    const config = await this.sanityService.getTtConfiguration<IConfigTT>(partnerId, language);

    const { styles = {}, tokens = [] } = (config.theme ?? []).reduce(
      (res, { name, hex }) => {
        res.styles = { ...res.styles, [name]: { predefinedColor: hex } };
        res.tokens = [...res.tokens, { property: name, cssVariable: `--c-${name}` }];
        return res;
      },
      {
        styles: {},
        tokens: [],
      }
    );
    appendCustomFaviconToHead(config.faviconUrl, this.domSanitizer);
    injectDynamicThemeTokensIntoHtmlHead(
      { customComponentStyles: { ...styles, ...config.customComponentStyles } },
      [...tokens, ...THEME_TOKENS]
    );
    return this.sanityService
      .getTtGeneralTranslations<ITtTranslations>()
      .then(sanityTranslations => {
        const translations = Object.entries(filterPrivateFields(sanityTranslations)).reduce(
          (res, [groupKey, values]) => {
            return {
              ...res,
              ...Object.entries(filterPrivateFields(values)).reduce(
                (
                  cmsTranslationsForGroup,
                  [fieldKey, { translations: t }]: [string, Record<string, string>]
                ) => ({
                  ...cmsTranslationsForGroup,
                  [`${groupKey}.${fieldKey}`]: t[language],
                }),
                {}
              ),
            };
          },
          {}
        );

        if (!leadId) {
          alert(translations['error.noLeadId']);
          return Promise.resolve(undefined);
        }

        return Promise.all([
          this.getLeadHeading(leadId),
          this.getLeadNbo(leadId),
          this.getCustomerRepPicture(leadId),
          this.getProjectContactPicture(leadId),
          this.getChecklist(leadId),
        ])
          .then(
            ([
              leadHeading,
              {
                ChecklistStepCompletedNotificationEnabled,
                Stage,
                Project: { ProjectPhase },
              },
              customerRepPicture,
              projectContactPicture,
              checklistData,
            ]) => {
              const activePhaseName: PhasesEnum = ProjectPhase || PhasesEnum.Definition;
              const activePhase = checklistData?.phases?.find(
                ({ phaseName }: any) => phaseName === activePhaseName
              );
              const activePhaseIndex = checklistData?.phases?.indexOf(activePhase);
              const { ObjectAddress, CustomerRep, ProjectContact } = leadHeading;
              const customerRepInfo = this.getCustomerRepInfo(
                CustomerRep,
                customerRepPicture,
                Stage,
                config
              );
              const projectContactInfo = this.getPersonInfo(
                ProjectContact || {},
                projectContactPicture
              );

              const { Street, ZipCode, City } = ObjectAddress;
              const clientAddress = `${Street && Street.trim()} \n ${ZipCode} ${City}`;
              const checklist: ProgressPhase[] = (
                checklistData?.phases?.length
                  ? checklistData.phases
                  : Object.values(PhasesEnum).map(phaseName => ({
                      steps: [],
                      completedPercent: 0,
                      phaseName,
                    }))
              )
                .map(({ steps, phaseName, ...phase }: any, index: number) => ({
                  phaseName,
                  title: phase?.[`title${langCode}`],
                  date:
                    phaseName === PhasesEnum.Definition && checklistData.homecheckDate
                      ? new Date(checklistData.homecheckDate)
                      : phaseName === PhasesEnum.Installation && checklistData.installationStartDate
                      ? new Date(checklistData.installationStartDate)
                      : phaseName === PhasesEnum.Closure && checklistData.commissioningDate
                      ? new Date(checklistData.commissioningDate)
                      : undefined,
                  endDate:
                    phaseName === PhasesEnum.Installation && checklistData.installationEndDate
                      ? new Date(checklistData.installationEndDate)
                      : undefined,
                  dateLabel:
                    phaseName === PhasesEnum.Definition
                      ? sanityTranslations.checklist.HomecheckDateLabel.translations[language]
                      : phaseName === PhasesEnum.Closure
                      ? sanityTranslations.checklist.CommissioningDateLabel.translations[language]
                      : '',
                  status:
                    index < activePhaseIndex
                      ? ProgressStatusEnum.done
                      : index === activePhaseIndex
                      ? ProgressStatusEnum.progress
                      : ProgressStatusEnum.none,
                  steps: steps
                    .filter(({ isVisibleToCustomer }: any) => isVisibleToCustomer)
                    .map((step: any) => ({
                      title: step[`customerTitle${langCode}`] ?? step[`title${langCode}`],
                      status:
                        index < activePhaseIndex || step.isChecked
                          ? ProgressStatusEnum.done
                          : ProgressStatusEnum.none,
                    })),
                }))
                .filter(phase => phase.title || phase.date);

              return {
                activePhaseIndex,
                notificationEnabled: ChecklistStepCompletedNotificationEnabled,
                leadId,
                clientAddress,
                customerRepInfo,
                projectContactInfo,
                checklist,
                logoLinkUrl: config.logoLinkUrl,
                logoImageUrl: config.logoImageUrl,
                heroHeaderImageUrl: config.heroHeaderImageUrl,
                translations: {
                  ...translations,
                  'phase.title': activePhase?.[`title${langCode}`].split('. ')[1],
                  'phase.label': translations[`checklist.${activePhaseName}Label`],
                  'phase.text': translations[`checklist.${activePhaseName}Text`],
                },
                stage: Stage,
              };
            }
          )
          .catch(reason => {
            console.log(reason);
            const activePhaseName: PhasesEnum = PhasesEnum.Definition;

            return Promise.resolve({
              activePhaseIndex: 0,
              notificationEnabled: false,
              leadId,
              customerRepInfo: this.getCustomerRepInfo(undefined, undefined, undefined, config),
              logoLinkUrl: config.logoLinkUrl,
              logoImageUrl: config.logoImageUrl,
              heroHeaderImageUrl: config.heroHeaderImageUrl,
              translations: {
                ...translations,
                'phase.label': translations[`checklist.${activePhaseName}Label`],
                'phase.text': translations[`checklist.${activePhaseName}Text`],
              },
            });
          });
      });
  }

  getCustomerRepInfo(
    CustomerRep: any,
    picture: any,
    Stage: any,
    config: IConfigTT
  ): ContactPersonData {
    if (Stage === 'NEW' || Stage === 'ABORT' || !CustomerRep) {
      return {
        fullName: config.fullName,
        center: config.organizationalUnit,
        phone: config.contactPhone,
        email: config.contactEmail,
      };
    } else {
      return this.getPersonInfo(CustomerRep || {}, picture);
    }
  }

  subscribeToEmailNotifications(leadId: string) {
    return this.getRequest(
      `${this.environment.backendNboUrl}/leads/${leadId}/project-state-change-notifications/subscribe`
    ).catch(() => undefined);
  }

  unsubscribeToEmailNotifications(leadId: string) {
    return this.getRequest(
      `${this.environment.backendNboUrl}/leads/${leadId}/project-state-change-notifications/unsubscribe`
    ).catch(() => undefined);
  }

  private getPersonInfo(person: any = {}, picture: any) {
    const { FirstName, LastName, Email, PhoneNumber, OrganizationalUnit, JobTitle } = person;
    return FirstName && LastName
      ? {
          fullName: `${FirstName} ${LastName}`,
          center: OrganizationalUnit,
          phone: PhoneNumber,
          email: Email,
          picture: picture?.FileContent ?? '',
          jobTitle: JobTitle ?? '',
        }
      : undefined;
  }

  private getRequest(...args: Parameters<typeof fetch>) {
    return fetch(...args).then(res => res.json());
  }
  private getChecklist(leadId: string) {
    return this.getRequest(`${this.environment.backendTheiaUrl}/connect/token`, {
      method: 'POST',
      body: new URLSearchParams({ grant_type: 'client_credentials' }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic dHJhY2t0cmFjZToxMDI1NWZhNy0xNzRlLTQ0NTUtODczMy02YTgxZTFlMzNmNzI=',
      },
      credentials: 'include',
    }).then(({ token_type, access_token }) =>
      this.getRequest(`${this.environment.backendTheiaUrl}/api/v1/projects/${leadId}/checklist`, {
        headers: { Authorization: `${token_type} ${access_token}` },
      })
    );
  }
  private getLeadHeading(leadId: string) {
    return this.getRequest(`${this.environment.backendNboUrl}/leads/${leadId}/heading`);
  }
  private getLeadNbo(leadId: string) {
    return this.getRequest(`${this.environment.backendNboUrl}/leads/${leadId}`);
  }
  private getCustomerRepPicture(leadId: string) {
    return this.getRequest(
      `${this.environment.backendNboUrl}/leads/${leadId}/files/CustomerRepPicture`
    ).catch(() => undefined);
  }

  private getProjectContactPicture(leadId: string) {
    return this.getRequest(
      `${this.environment.backendNboUrl}/leads/${leadId}/files/ProjectManagerPicture`
    ).catch(() => undefined);
  }

  private capitalizeFirstLetter(txt: string) {
    return txt.charAt(0).toUpperCase() + txt.slice(1);
  }
}
