import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhasesEnum, ProgressPhase } from '../core';

@Component({
  selector: 'phase-date',
  standalone: true,
  template: `<h5 class="phase-date pb-1" *ngIf="phase.date">
    <span *ngIf="phase.dateLabel">{{ phase.dateLabel }}: </span>
    <span
      *ngIf="
        (phase.phaseName === PhasesEnum.Installation && activePhaseIndex < 3) ||
        (phase.phaseName === PhasesEnum.Closure && !isFinalDateIsLessThen2Weeks)
      ">
      {{ translations['checklist.MontageDateMonthRange'] }}
    </span>
    <span>
      {{
        phase.date
          | date
            : ((phase.phaseName === PhasesEnum.Installation && activePhaseIndex < 3) ||
              (phase.phaseName === PhasesEnum.Closure && !isFinalDateIsLessThen2Weeks)
                ? 'MMMM yyyy'
                : 'dd. MMMM yyyy')
      }}
      {{
        phase.phaseName === PhasesEnum.Installation && installationDate && activePhaseIndex < 3
          ? '*'
          : ''
      }}
    </span>
    <span
      *ngIf="
        phase.endDate && (phase.phaseName !== PhasesEnum.Installation || activePhaseIndex >= 3)
      ">
      - {{ phase.endDate | date: 'dd. MMMM yyyy' }}
    </span>
    <p
      *ngIf="
        (installationDate &&
          phase.phaseName === PhasesEnum.Installation &&
          activePhaseIndex === 3 &&
          !isInstallationDateIn3DaysOrLess) ||
        (finalDate &&
          phase.phaseName === PhasesEnum.Closure &&
          isFinalDateIsLessThen2Weeks &&
          !isFinalDateIn3DaysOrLess)
      ">
      {{ translations['checklist.MontageDateShortBefore'] }}
    </p>
  </h5>`,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule],
})
export class PhaseDateComponent implements OnChanges {
  @Input() checklist: ProgressPhase[];
  @Input() translations: Record<string, string>;
  @Input() activePhaseIndex: number;
  @Input() phase: ProgressPhase;

  public isInstallationDateIn3DaysOrLess = false;
  public isFinalDateIn3DaysOrLess = false;
  public isFinalDateIsLessThen2Weeks = false;
  public installationDate: number;
  public finalDate: number;
  protected readonly PhasesEnum = PhasesEnum;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.checklist) {
      this.installationDate = this.checklist
        .find(phase => phase.phaseName === PhasesEnum.Installation)
        ?.date?.setHours(0);
      this.isInstallationDateIn3DaysOrLess =
        this.installationDate &&
        this.installationDate < new Date().setHours(0) + 1000 * 60 * 60 * 24 * 3;

      this.finalDate = this.checklist
        .find(phase => phase.phaseName === PhasesEnum.Closure)
        ?.date?.setHours(0);
      this.isFinalDateIn3DaysOrLess =
        this.finalDate && this.finalDate < new Date().setHours(0) + 1000 * 60 * 60 * 24 * 3;
      this.isFinalDateIsLessThen2Weeks =
        this.finalDate && this.finalDate < new Date().setHours(0) + 1000 * 60 * 60 * 24 * 14;
    }
  }
}
