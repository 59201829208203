const PROJECT_ID = '3dnuszhs';
const DATASET = 'development';

if (!PROJECT_ID || !DATASET) {
  throw new Error(
    'Sanity project ID and dataset name are required. Go into `track-and-trace/src/environments` and set them.'
  );
}
export const environment = {
  production: false,
  hmr: false,
  backendNboUrl: 'https://nbo-api-dev.offerten-rechner.ch/api/nbo',
  backendTheiaUrl: 'https://theia-api-dev.offerten-rechner.ch',
  sanity: {
    projectId: PROJECT_ID,
    dataset: DATASET,
    apiVersion: '2022-05-01',
    useCdn: false, // set to false for fresh data
  },
};
