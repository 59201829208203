import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ContactPersonData } from '../core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'theia-cc-contact',
  standalone: true,
  template: `<section class="d-flex gap-2 align-items-start justify-content-between flex-wrap">
    <div class="flex-column gap-2">
      <div>
        <h3>{{ contactPersonData.fullName }}</h3>
        <h3 *ngIf="contactPersonData.jobTitle">{{ contactPersonData.jobTitle }}</h3>
        <h3>{{ contactPersonData.center }}</h3>
      </div>
      <div>
        <h5>
          <a href="tel:{{ contactPersonData.phone }}">{{ contactPersonData.phone }}</a>
        </h5>
        <h5>
          <a href="mailto:{{ contactPersonData.email }}">{{ contactPersonData.email }}</a>
        </h5>
      </div>
    </div>
    <div class="d-flex justify-content-center" *ngIf="contactPersonData.picture">
      <img
        class="w-100 contact-person-image"
        [src]="contactPersonData.picture"
        alt="contact person image" />
    </div>
  </section>`,
  imports: [CommonModule],
  encapsulation: ViewEncapsulation.None,
})
export class ContactComponent {
  @Input() contactPersonData: ContactPersonData;
}
